import React from "react";
import Layout from "../comps/reusable/Layout";
import { graphql } from "gatsby";
import Seo from "../comps/reusable/Seo";
import {
  CareerPageSection,
  ContactSection,
  HeroSection,
  InfoSection,
  PlatformSection,
  SoftwareSection,
  SuggestionSection,
} from "../styles/pages/ats";
import lap from "../images/ats/lap.png";
import heroFigureOne from "../images/ats/hero-figure-one.png";
import heroFigureTwo from "../images/ats/hero-figure-two.png";
import heroFigureThree from "../images/ats/hero-figure-three.png";
import heroFigureFour from "../images/ats/hero-figure-four.png";
import softwareImage from "../images/ats/software-image.png";
import careerPage from "../images/ats/career-page.png";
import recomendados from "../images/ats/recomendados.png";
import ats from "../images/ats/ats.png";
import stars from "../images/ats/stars.png";
import pencil from "../images/ats/pencil.png";
import message from "../images/ats/message.png";
import documents from "../images/ats/documents.png";
import users from "../images/ats/users.png";
import search from "../images/ats/search.png";
import mail from "../images/ats/mail.png";
import desktop from "../images/ats/desktop.png";
import windows from "../images/ats/windows.png";
import pieces from "../images/ats/pieces.png";
import chartPie from "../images/ats/chart-pie.png";
import platforms from "../images/ats/platforms.png";
import { CompaniesSection } from "../styles/pages/home";
import Carousel from "../comps/reusable/Carousel";
import { useTranslation } from "react-i18next";

const dermavanBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FDermavan%20Negro.png?alt=media&token=824f3023-ea05-41aa-adb8-9979dddf73d9";
const flatBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FFlat%20Negro.png?alt=media&token=3ac0b29b-c34e-422f-8349-8e38eff2f640";
const leadgeniosBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FlEADGENIOS%20Negro.png?alt=media&token=92aba62d-ab6c-404f-8c0a-27fca2129dfb";
const echaleBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FLOGO-E%CC%81chale-09%20Negro.png?alt=media&token=0e146370-0bab-4086-b4c7-4e2719cfaedd";
const walliaBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FWallia%20Negro.png?alt=media&token=4d6b80e1-db56-4af0-ac3a-f9868de38f9b";

const dermavanWhite =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FDERMAVAN.png?alt=media&token=87607873-a5ca-4f67-993d-7f8340da2290";
const flatWhite =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FFLAT.png?alt=media&token=53898a43-6f4f-4283-b5e2-27b25e26be53";
const leadgeniosWhite =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FLeadgenios.png?alt=media&token=fb88adf5-aea3-48bb-b52e-54f67ab3626f";
const echaleWhite =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FLOGO-E%CC%81chale-09.png?alt=media&token=9eb01828-c15e-423c-bc81-eba1ada01b60";
const walliaWhite =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FWallia.png?alt=media&token=2a28131a-5251-47b8-a666-18de30c7fc1e";

const Ats = () => {
  const { t } = useTranslation("ats");

  return (
    <>
      <Seo
        title="Comienza a publicar vacantes gratis. ¡Recibe talento al instante!"
        description="Publica dos vacantes gratis. Recibe talento en 30 segundos. Administra y da seguimiento a todos tus procesos desde un solo lugar."
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FSEO%2FTAG3.png?alt=media&token=1e0186ca-89d1-4e65-9386-e05e7e7c4ce7"
      />
      <Layout>
        <HeroSection>
          <div className="main-landing-container-column">
            <h1>{t("heroSubTitle")}</h1>
            <h3>{t("heroTitle")}</h3>
            <p>{t("heroDesc")}</p>
            <a
              href="https://api.whatsapp.com/send/?phone=527771369552&text=¡Hola Alexa! Me interesa saber más sobre los servicios de LaPieza."
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("requestInfo")}
            </a>
            <figure>
              <img
                className="hero-figure hero-figure-one"
                src={heroFigureOne}
                alt="Figure LaPieza"
              />
              <img
                className="hero-figure hero-figure-two"
                src={heroFigureTwo}
                alt="Figure LaPieza"
              />
              <img
                className="hero-figure hero-figure-three"
                src={heroFigureThree}
                alt="Figure LaPieza"
              />
              <img
                className="hero-figure hero-figure-four"
                src={heroFigureFour}
                alt="Figure LaPieza"
              />
              <img
                className="main-image-hero"
                src={lap}
                alt="Platform LaPieza"
              />
            </figure>
          </div>
        </HeroSection>
        <CompaniesSection>
          <div className="companies-section">
            <Carousel
              logos={[
                dermavanBlack,
                flatBlack,
                leadgeniosBlack,
                echaleBlack,
                walliaBlack,
              ]}
            />
          </div>
        </CompaniesSection>
        <SoftwareSection>
          <h2>{t("softwareTitle")}</h2>
          <div className="main-landing-container-row">
            <figure>
              <img src={softwareImage} alt="Platform LaPieza" />
            </figure>
            <article>
              <h3>{t("softwareItemTitle")}</h3>
              <p className="description-article">
                {t("softwareItemDescription")}
              </p>
              <p className="message-article">{t("softwareItemText")}</p>
              <a
                href="https://api.whatsapp.com/send/?phone=527771369552&text=¡Hola Alexa! Me interesa saber más sobre los servicios de LaPieza."
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("requestInfo")}
              </a>
            </article>
          </div>
        </SoftwareSection>
        <CareerPageSection>
          <div className="main-landing-container-row">
            <article>
              <h2>{t("careerItemTitle")}</h2>
              <p>{t("careerItemDescription")}</p>
            </article>
            <figure>
              <img src={careerPage} alt="Platform LaPieza" />
            </figure>
          </div>
        </CareerPageSection>
        <SuggestionSection>
          <div className="suggestion-section main-landing-container-row">
            <figure>
              <img src={recomendados} alt="Hunting LaPieza" />
            </figure>
            <article>
              <h2>{t("suggestionsItemSubtitle1")}</h2>
              <h3>{t("suggestionsItemTitle1")}</h3>
              <p>{t("suggestionsItemDescription1")}</p>
            </article>
          </div>
          <div className="candidates-section main-landing-container-row">
            <article>
              <h2>{t("suggestionsItemSubtitle2")}</h2>
              <h3>{t("suggestionsItemTitle2")}</h3>
              <p>{t("suggestionsItemDescription2")}</p>
            </article>
            <figure>
              <img src={ats} alt="ATS LaPieza" />
            </figure>
          </div>
          <div className="info-section main-landing-container-row">
            <article>
              <img src={stars} alt="Magic Actions LaPieza" />
              <h5>{t("suggestionsLisTitle1")}</h5>
              <p>{t("suggestionsLisDescription1")}</p>
            </article>
            <article>
              <img src={message} alt="Contacto LaPieza" />
              <h5>{t("suggestionsLisTitle2")}</h5>
              <p>{t("suggestionsLisDescription2")}</p>
            </article>
            <article>
              <img src={pencil} alt="Proceso LaPieza" />
              <h5>{t("suggestionsLisTitle3")}</h5>
              <p>{t("suggestionsLisDescription3")}</p>
            </article>
          </div>
        </SuggestionSection>
        <InfoSection>
          <h2>{t("InfoTitle")}</h2>
          <div className="info-list main-landing-container-column">
            <article>
              <img
                src={documents}
                alt="iconono LaPieza"
                className="info-icon"
              />
              <h5>{t("infoItemTitle1")}</h5>
              <p>{t("infoItemDescription1")}</p>
            </article>
            <article>
              <img src={users} alt="iconono LaPieza" className="info-icon" />
              <h5>{t("infoItemTitle2")}</h5>
              <p>{t("infoItemDescription2")}</p>
            </article>
            <article>
              <img src={search} alt="iconono LaPieza" className="info-icon" />
              <h5>{t("infoItemTitle3")}</h5>
              <p>{t("infoItemDescription3")}</p>
            </article>
            <article>
              <img src={mail} alt="iconono LaPieza" className="info-icon" />
              <h5>{t("infoItemTitle4")}</h5>
              <p>{t("infoItemDescription4")}</p>
            </article>
            <article>
              <img src={desktop} alt="iconono LaPieza" className="info-icon" />
              <h5>{t("infoItemTitle5")}</h5>
              <p>{t("infoItemDescription5")}</p>
            </article>
            <article>
              <img src={windows} alt="iconono LaPieza" className="info-icon" />
              <h5>{t("infoItemTitle6")}</h5>
              <p>{t("infoItemDescription6")}</p>
            </article>
            <article>
              <img src={pieces} alt="iconono LaPieza" className="info-icon" />
              <h5>{t("infoItemTitle7")}</h5>
              <p>{t("infoItemDescription7")}</p>
            </article>
            <article>
              <img src={chartPie} alt="iconono LaPieza" className="info-icon" />
              <h5>{t("infoItemTitle8")}</h5>
              <p>{t("infoItemDescription8")}</p>
            </article>
            <article>
              <img src={desktop} alt="iconono LaPieza" className="info-icon" />
              <h5>{t("infoItemTitle9")}</h5>
              <p>{t("infoItemDescription9")}</p>
            </article>
          </div>
        </InfoSection>
        <PlatformSection>
          <div className="main-landing-container-row">
            <article>
              <h4>{t("platformSubtitle")}</h4>
              <h5>{t("platformTitle")}</h5>
              <p>{t("platformDescription")}</p>
              <a
                href="https://api.whatsapp.com/send/?phone=527771369552&text=¡Hola Alexa! Me interesa saber más sobre los servicios de LaPieza."
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("requestInfo")}
              </a>
            </article>
            <figure>
              <img src={platforms} alt="Integraciones LaPieza" />
            </figure>
          </div>
        </PlatformSection>
        <ContactSection>
          <div className="main-landing-container-column">
            <figure>
              <img
                className="hero-figure hero-figure-one"
                src={heroFigureOne}
                alt="Figure LaPieza"
              />
              <img
                className="hero-figure hero-figure-two"
                src={heroFigureTwo}
                alt="Figure LaPieza"
              />
              <img
                className="hero-figure hero-figure-three"
                src={heroFigureThree}
                alt="Figure LaPieza"
              />
              <img
                className="hero-figure hero-figure-four"
                src={heroFigureFour}
                alt="Figure LaPieza"
              />
            </figure>
            <h2>{t("contactTitle")}</h2>
            <a
              href="https://api.whatsapp.com/send/?phone=527771369552&text=¡Hola Alexa! Me interesa saber más sobre los servicios de LaPieza."
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("requestInfo")}
            </a>
            <p>{t("contactDescription")}</p>
            <div className="companies-list">
              <Carousel
                logos={[
                  dermavanWhite,
                  flatWhite,
                  leadgeniosWhite,
                  echaleWhite,
                  walliaWhite,
                ]}
              />
            </div>
          </div>
        </ContactSection>
      </Layout>
    </>
  );
};

export default Ats;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
